import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../components/hero/hero-chevron";
import SEO from "../../components/seo/seo";
import Button from "../../components/custom-widgets/button";
import Icon from "../../components/custom-widgets/icon";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import List from "../../components/custom-widgets/list";
import MobileAppDownloadBtn from "../../components/mobile-online-banking/mobile-app-download-btn";

import getHeroImgVariables from "../../helpers/getHeroImgVariables";

import WaFdMobileApp from "../../images/wafd-mobile-app.gif";

const PersonalBanking = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/personal-banking/hero-personal-banking-052423-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/hero-personal-banking-052423-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/hero-personal-banking-052423-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/hero-personal-banking-052423-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/hero-personal-banking-052423-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/hero-personal-banking-052423-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/personal-banking/hero-personal-banking-052423-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
        allStrapiProducts(filter: {Name: {regex: "/(\\d+-Month CD Special|\\d+-\\d+-Month CD Special)/"}}) {
        nodes {
          Name
          ProductData {
            name
            value
          }
        }
      }
    }
  `);

  // Function to find the Product with the highest "APY - Eligible Checking" value
  const getHighestAPYEligibleCheckingProductData = () => {
    const APY_ELIGIBLE_CHECKING = imgData.allStrapiProducts.nodes.filter((node) =>
      node.ProductData.find((product) => product.name === "APY - Eligible Checking")
    );

    let maxValue = 0;
    let maxValueProductData = null;

    APY_ELIGIBLE_CHECKING.forEach((product) => {
      product.ProductData.forEach((data) => {
        if (data.name === "APY - Eligible Checking") {
          const percentProValue = parseFloat(data.value.replace("%", ""));
          if (percentProValue > maxValue) {
            maxValue = percentProValue;
            maxValueProductData = product;
          }
        }
      });
    });

    return { value: maxValue, product: maxValueProductData };
  };

  const MAX_CD_SPECIAL_APY = getHighestAPYEligibleCheckingProductData().value.toFixed(2) + "%";

  const heroChevronData = {
    id: "personal-banking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Family playing while cooking in the kitchen",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Lock in up to " + MAX_CD_SPECIAL_APY + " Annual Percentage Yield on a CD Special"
          }
        },
        {
          id: 2,
          button: {
            id: "pb-hero-cta",
            class: "btn-white",
            text: "CD Specials",
            url: "/personal-banking/savings-account/certificate-of-deposit"
          }
        }
      ]
    }
  };

  const title = "Personal Banking Services",
    description =
      "Discover personal banking services at WaFd Bank. From secure online checking and savings accounts to financial resources, we've got you covered.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-personal-banking-052423.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      title: "Personal Banking",
      active: true
    }
  ];

  const iconBlocksData = [
    {
      title: "Checking Accounts",
      iconSrc: "../../images/icons/icon-spending-account.svg",
      description:
        "Whether it's Interest Checking, Rewards Checking, or *Free Checking, we have an account tailored for you.",
      linkText: "WaFd Checking Accounts",
      linkUrl: "/personal-banking/checking-account",
      linkId: "spending-account-page-link",
      button: {
        id: "spending-accounts-open-account-btn",
        text: "Open an Account",
        url: "/open-an-account",
        class: "btn-primary btn-block",
        showIcon: false
      }
    },
    {
      title: "Savings Accounts",
      iconSrc: "../../images/icons/icon-savings-account.svg",
      description: "When it comes to saving, choose the interest rate and account features that fit your goals.",
      linkText: "WaFd Savings Accounts",
      linkUrl: "/personal-banking/savings-account",
      linkId: "savings-account-page-link",
      button: {
        id: "savings-accounts-open-account-btn",
        text: "Open an Account",
        url: "/open-an-account",
        class: "btn-primary btn-block",
        showIcon: false
      }
    },
    {
      title: "CDs",
      iconSrc: "../../images/icons/icon-cd.svg",
      description: "Grow your money faster with a Certificate of Deposit (CD).",
      linkText: "WaFd CD Accounts",
      linkUrl: "/personal-banking/savings-account/certificate-of-deposit",
      linkId: "cds-account-page-link",
      button: {
        id: "cds-accounts-open-account-btn",
        text: "Open an Account",
        url: "/open-an-account",
        class: "btn-primary btn-block",
        showIcon: false
      }
    },
    {
      title: "Home Loans",
      iconSrc: "../../images/icons/icon-home-loans.svg",
      description:
        "First time home buyer or seasoned investor, you deserve a <a href='/blog/home-ownership/what-is-a-portfolio-loan' id='portfolio-lender-blog-link'>Portfolio Lender</a> that is with you for the life of your loan.",
      linkText: "WaFd Home Loans",
      linkUrl: "/personal-banking/home-loans",
      linkId: "home-loans-account-page-link",
      button: {
        id: "home-loans-contact-loan-officer-btn",
        text: "Find a Loan Officer",
        url: "/personal-banking/contact-loan-officer",
        class: "btn-primary btn-block",
        showIcon: false
      }
    },
    {
      title: "IRAs",
      iconSrc: "../../images/icons/icon-ira.svg",
      description:
        "Save for retirement with an Individual Retirement Account (IRA), that provides no-fee rollovers and transfers.",
      linkText: "WaFd Traditional and Roth IRAs",
      linkUrl: "/personal-banking/retirement-ira-accounts",
      linkId: "iras-account-page-link",
      button: {
        id: "iras-locations-btn",
        text: "Make an Appointment",
        url: "/locations",
        class: "btn-primary btn-block",
        showIcon: false
      }
    }
  ];

  const creditCardListData = {
    greenCircleCheckmark: true,
    accountName: "credit-card-list",
    items: [
      {
        id: 1,
        text: "No annual fee"
      },
      {
        id: 2,
        text: "Contactless cards that allow you to tap and go!"
      },
      {
        id: 3,
        text: "Benefits** provided by Visa<sup>&reg;</sup> including auto rental coverage for damage due to collision or theft, and access to roadside assistance. Visa<sup>&reg;</sup> Signature benefits can also include extended warranty protection on eligible items."
      }
    ]
  };

  const onlineMobileBankingListItems = {
    accountName: "online-mobile-banking-list",
    items: [
      {
        id: 1,
        text: "Pay bills online or with your smartphone"
      },
      {
        id: 2,
        text: "Check your balance, view transactions and transfer money"
      },
      {
        id: 3,
        text: "Deposit checks with your smart phone and mobile photo deposit"
      },
      {
        id: 4,
        text: "Pay friends with person-to-person transfers"
      },
      {
        id: 5,
        text: "Make online wire transfers"
      },
      {
        id: 6,
        text: "Reach your financial goals by setting budgets, managing spending, and tracking savings"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Safe and Convenient Personal Banking Options</h1>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          <div className="col card border-0">
            <h3 className="text-success">
              Simple. Straightforward. Smart. That's what personal banking from WaFd Bank is all about.
            </h3>
            <p>
              We have one goal in mind - to help you make the most of your money! WaFd Bank exists to provide financial
              resources, tools and accounts to help you manage your personal finances.
            </p>
          </div>
          {/* Icon Blocks */}
          {iconBlocksData.map((data, index) => (
            <div className="col mb-4 card border-0" id="" key={index}>
              <div className="bg-light card-body border-radius-top-12">
                <div className="d-flex align-items-center mb-3 justify-content-between">
                  <h3 className="text-success mb-0" dangerouslySetInnerHTML={{ __html: data.title }} />
                  <img src={data.iconSrc} alt="" className="ml-2" style={{ width: "64px" }} />
                </div>
                <p dangerouslySetInnerHTML={{ __html: data.description }} />
                <Link to={data.linkUrl} id={data.linkId} className="text-decoration-none font-weight-bold">
                  {data.linkText}
                  <Icon name="arrow-right" class="ml-2" />
                </Link>
              </div>
              <div className="card-footer border-0 border-radius-bottom-12">
                <Button {...data.button} />
              </div>
            </div>
          ))}
        </div>
        <p id="free-checking-disclaimer" className="text-sm text-muted mb-0">
          *Nonsufficient funds charge may apply.
        </p>
      </section>
      {/* Credit Card Section */}
      <section className="bg-light">
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-6 order-1 order-md-0 mt-4 mt-md-0">
              <h3 className="text-success">Cash Back Credit Card from WaFd Bank</h3>
              <List {...creditCardListData} />
              <div className="row justify-content-around align-items-center">
                <div className="col-lg-6 mb-3 mb-lg-0">
                  <Link
                    to="/personal-banking/credit-cards"
                    id="credit-card-page-link"
                    className="text-decoration-none font-weight-bold"
                  >
                    Cash Back Credit Card
                    <Icon name="arrow-right" class="ml-2" />
                  </Link>
                </div>
                <div className="col-lg-6">
                  <a
                    href="https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-cash-back-rewards-visa-credit-card"
                    className="btn btn-primary w-100 w-sm-auto"
                    rel="noopener noreferrer"
                    target="_blank"
                    id="credit-card-apply-now-button"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <StaticImage
                src="../../images/thumbnail-cash-back-cc-052423.jpg"
                alt="WaFd Cash Back Credit Card and man on mobile phone holing credit card."
                quality="100"
                placeholder="blurred"
              />
            </div>
          </div>
          <p className="text-sm text-muted mb-0" id="credit-card-benefits-disclaimer">
            **Certain terms, conditions, and exclusions apply. Subject to credit approval. Please refer to your Visa
            Guide to Benefits and Cardholder Agreement for details.
          </p>
        </div>
      </section>
      {/* Online Banking */}
      <section className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3 d-none d-md-block">
            <img src={WaFdMobileApp} className="w-100 w-sm-50 w-md-100" alt="WaFd Bank Mobile App home screen" />
          </div>
          <div className="col-md-6 col-lg-9">
            <h3 className="text-success">Convenient and Secure Online Banking</h3>
            <div className="row">
              <div className="col-lg-7 mb-3">
                <List {...onlineMobileBankingListItems} />
                <Link to="/personal-banking/online-banking" id="online-banking-page-link" className="btn btn-link">
                  WaFd Online Banking and Mobile App
                </Link>
              </div>
              <div className="col-lg-5">
                <p className="text-success font-weight-bold text-sm">Get the WaFd Bank mobile app</p>
                <div className="row">
                  <div className="col-6 col-md-auto col-lg-6 d-none d-md-block">
                    <StaticImage
                      src="../../images/wafd-mobile-app-qr-code.png"
                      alt="WaFd App QR Code"
                      placeholder="blurred"
                      quality="100"
                      className="mb-3"
                    />
                    <p className="text-gray-60 text-nowrap text-sm">Scan to download</p>
                  </div>
                  <div className="col-sm-6 d-flex flex-row flex-md-column">
                    <div className="mr-4 mr-md-0">
                      <MobileAppDownloadBtn
                        idName="apple-store"
                        storeBtn="apple"
                        url="https://apps.apple.com/us/app/wafd-bank/id1054549841"
                      />
                      <p className="text-gray-60 text-nowrap text-sm">For Apple devices</p>
                    </div>
                    <div className="">
                      <MobileAppDownloadBtn
                        idName="google-play-store"
                        storeBtn="google"
                        url="https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US"
                      />
                      <p className="text-gray-60 text-nowrap text-sm">For Android devices</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default PersonalBanking;
